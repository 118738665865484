<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <custom-header />
      <div class="mainContainer bg-blue">
        <div class="titleContainer bg-blue border-gray">
          <p class="titleText">{{ $t("labels.videos") }}</p>
        </div>
        <div class="border-bottom" />
        <div v-for="(item, index) in videos" :key="index" class="itemContainer">
          <div class="videoImageContainer">
            <img
              class="videoImage"
              :src="`data:image/png;base64,${item.image}`"
            />
            <img
              class="videoIcon btn"
              :src="videoIcon"
              @click="openVideo(item.url)"
            />
          </div>
          <div class="descContainer">
            <p class="itemTitle">{{ item.title }}</p>
            <p class="itemDesc">{{ item.description }}</p>
            <p class="itemDate">{{ getFormattedDate(item.created_at) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CustomHeader from "../components/CustomHeader.vue";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "VideosPage",
  components: {
    CustomHeader,
  },
  data() {
    return {
      videoIcon: require("../assets/image/svg/videos.svg"),
    };
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    getFormattedDate(dt) {
      return moment(dt).format("DD-MM-YYYY");
    },

    openVideo(url) {
      window.open(url, "_blank");
    },
  },
  computed: mapState({
    videos: (state) => state.main.videos,
  }),
  mounted() {
    this.$store.dispatch("main/getVideos");
  },
};
</script>

<style scoped>
.itemContainer {
  padding: 10px 0px;
  width: 100%;
  /* border-top: 1px solid gray; */
  border-bottom: 1px solid gray;
  display: flex;
  flex-direction: row;
}
.border-bottom {
  border-bottom: 1px solid gray;
}
.itemTitle {
  color: white;
  font-size: 18px;
  font-weight: bold;
}
.itemDesc {
  color: white;
  font-size: 15px;
  width: 100%;
  flex-wrap: wrap;
  margin: 5px 0;
  overflow-wrap: break-word;
}
.itemDate {
  color: white;
  font-size: 13px;
}
.border-gray {
  border: 1px solid gray;
  border-bottom: none;
  min-width: 150px;
}

.videoImageContainer {
  width: 33%;
  padding-top: 20%;
  position: relative;
  background-color: black;
  border-radius: 8px;
  overflow: hidden;
}
.videoImage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.videoIcon {
  position: absolute;
  height: 50px;
  width: 50px;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}
@media only screen and (max-width: 700px) {
  .videoIcon {
    height: 30px;
    width: 30px;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
  }
}
.descContainer {
  display: flex;
  flex: 1;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
}
</style>